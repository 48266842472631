import React, { useEffect, useState } from "react";
import Navbar from "../comp/Navbar";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Overview() {
  let [currentComic, setCurrentComic] = useState([]);
  let [totalComics, setTotalComics] = useState(0);
  let [pageUpBtn, setPageupBtns] = useState([]);
  let { comic } = useParams();
  useEffect(() => {
    getComic();
  }, []);
  async function getComic() {
    try {
      let response = await fetch(
        "https://shortwash.com/admin/alladultcomics/getmulti.php",
        {
          method: "POST",
          body: JSON.stringify({
            query: `SELECT el.*,c.cover from episode_list as el JOIN comics as c ON el.cid = c.cid WHERE el.cid IN (SELECT cid from comics WHERE title = '${comic}');`,
          }),
        }
      );
      let responseData = await response.json();
      console.log(responseData);
      if ((responseData.response = "success")) {
        setCurrentComic(responseData.data);
      } else {
        setCurrentComic([]);
      }
    } catch (error) {
      console.log(error);
      setCurrentComic([]);
    }
  }
  return (
    <div style={{ minHeight: "100vh", backgroundColor: "black" }}>
      <Navbar />
      <div className="container py-5">
        <div className="my-2 text-center">
          <span style={{ color: "white" }}>
            AllAdultComics is your gateway to a world where fantasy meets
            desire. Our curated collection of adult comics brings together
            artistry, passion, and boundless creativity. Join us as we explore
            provocative themes, ignite imaginations, and celebrate the beauty of
            visual storytelling.
          </span>
        </div>
        <div className="d-flex justify-content-center mb-5">
          <h1 style={{ color: "white" }}>{comic}</h1>
        </div>
        {currentComic.length > 0 && (
          <div className="row">
            <Helmet>
              <title>AllAdultComics - {comic}</title>
            </Helmet>
            <div className="col-12 col-md-4">
              <div className="d-flex justify-content-center">
                <img src={currentComic[0].cover} alt="Comic Cover" />
              </div>
            </div>
            <div className="col-12 col-md-8 my-3">
              <div className="mb-3">
                <h4 style={{ color: "white" }}>Episode List</h4>
              </div>
              {currentComic.map((item) => {
                return (
                  <a
                    href={`/episode/${comic}/${item.elid}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div style={{ fontStyle: "italic" }} className="my-2">
                      <h6 style={{ color: "white" }}>{item.episode_title}</h6>
                      <span style={{ color: "grey" }}>
                        Posted on {item.date_added}
                      </span>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// --breakpoint-xs: 0;
// --breakpoint-sm: 576px;
// --breakpoint-md: 768px;
// --breakpoint-lg: 992px;
// --breakpoint-xl: 1200px;
// max-width: 100%;
// height: auto;
// padding-top: 0 !important;
// background-color: #f5f5f5;
// -webkit-animation: fadeForLazyload 0.5s linear;
// margin: 0 auto 20px;
// cursor: pointer;
