import React, { useEffect, useRef, useState } from "react";
import Navbar from "../comp/Navbar";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Episode() {
  let [currentComic, setCurrentComic] = useState([]);
  let { comic, episode } = useParams();
  let [allImages, setAllImages] = useState([]);
  let [imageTotal, setImageTotal] = useState(0);
  let [loading, setLoading] = useState(true);
  let counter = useRef(0);
  useEffect(() => {
    getComic();
  }, []);
  async function getComic() {
    try {
      let response = await fetch(
        "https://shortwash.com/admin/alladultcomics/getmulti.php",
        {
          method: "POST",
          body: JSON.stringify({
            query: `SELECT ed.*,el.episode_title FROM episode_data as ed JOIN episode_list as el ON el.elid = ed.elid WHERE ed.elid = ${episode};`,
          }),
        }
      );
      let responseData = await response.json();
      // console.log(responseData);
      if ((responseData.response = "success")) {
        setCurrentComic(responseData.data);
        let tempArr = responseData.data[0].episode_images.split(",");
        setAllImages(tempArr);
        setImageTotal(tempArr.length);
      } else {
        setCurrentComic([]);
      }
    } catch (error) {
      console.log(error);
      setCurrentComic([]);
    }
  }

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "black" }}>
      <Navbar />
      {loading && imageTotal && (
        <div className="d-flex justify-content-center">
          <div
            style={{
              backgroundColor: "red",
              width: "80%",
              height: 30,
              borderRadius: 7,
              overflow: "hidden",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <span style={{ color: "white" }}>Loading images...</span>
          </div>
        </div>
      )}
      <div className="container py-5">
        {currentComic.length > 0 && (
          <React.Fragment>
          <Helmet>
          <title>AllAdultComics - {comic} | {currentComic[0].episode_title}</title>
          </Helmet>
            <div className="d-flex justify-content-center mb-5">
              <h1 style={{ color: "white" }}>{comic}</h1>
            </div>
            <div className="my-3" style={{ color: "white" }}>
              <a href="/" style={{ textDecoration: "none", color: "white" }}>
                Home
              </a>{" "}
              |{" "}
              <a
                href={`/overview/${comic}`}
                style={{ textDecoration: "none", color: "white" }}
              >
                {comic}
              </a>{" "}
              |{" "}
              <a
                href={`/episode/${comic}/${currentComic[0].episode_title}`}
                style={{ textDecoration: "none", color: "white" }}
              >
                {currentComic[0].episode_title}
              </a>
            </div>
          </React.Fragment>
        )}
        <div className="text-center">
          {allImages.map((item, index) => {
            return (
              <div className="row justify-content-center" key={index}>
                <img
                  src={item}
                  alt="Comic"
                  onLoad={(e) => {
                    counter.current += 1;
                    if (imageTotal) {
                      if (imageTotal <= counter.current) {
                        setTimeout(() => {
                          setLoading(false);
                        }, 1500);
                      }
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
