import React from "react";

export default function Navbar() {
  return (
    <div
      style={{ backgroundColor: "black", height: 150 }}
      className="d-flex justify-content-center"
    >
      <a href="/">
        <img
          src={require("../../assets/alladultcomics.png")}
          alt="AllAdultComics logo"
          style={{ width: 150 }}
        />
      </a>
    </div>
  );
}
