import React, { useEffect, useState } from "react";
import Navbar from "../comp/Navbar";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home() {
  let [allComics, setAllComics] = useState([]);
  let [totalComics, setTotalComics] = useState(0);
  let [pageUpBtn, setPageupBtns] = useState([]);
  useEffect(() => {
    getComics();
    getTotalComics();
  }, []);
  async function getComics() {
    try {
      let response = await fetch(
        "https://shortwash.com/admin/alladultcomics/getmulti.php",
        {
          method: "POST",
          body: JSON.stringify({
            query: "SELECT * FROM comics LIMIT 18 OFFSET 0",
          }),
        }
      );
      let responseData = await response.json();
      // console.log(responseData);
      if ((responseData.response = "success")) {
        setAllComics(responseData.data);
      } else {
        setAllComics([]);
      }
    } catch (error) {
      console.log(error);
      setAllComics([]);
    }
  }
  async function getTotalComics() {
    try {
      let response = await fetch(
        "https://shortwash.com/admin/alladultcomics/getmulti.php",
        {
          method: "POST",
          body: JSON.stringify({
            query: "SELECT COUNT(cid) as totalcomics FROM comics",
          }),
        }
      );
      let responseData = await response.json();
      // console.log(responseData);
      if (responseData.response == "success") {
        setTotalComics(responseData.data[0].totalcomics);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (totalComics > 0) {
      let totalBtnArr = [];
      totalBtnArr.push(
        <a href={`/page/1`} style={{ textDecoration: "none", color: "white" }}>
          <div
            className="border border-danger py-2 px-3 m-1"
            style={{ cursor: "pointer" }}
          >
            <span>1</span>
          </div>
        </a>
      );
      setPageupBtns(totalBtnArr);
    }
  }, [totalComics]);
  return (
    <div style={{ minHeight: "100vh", backgroundColor: "black" }}>
      <Helmet>
        
      </Helmet>
      <Navbar />
      <div className="container py-5">
        <div className="my-2 text-center">
          <span style={{ color: "white" }}>
            AllAdultComics is your gateway to a world where fantasy meets
            desire. Our curated collection of adult comics brings together
            artistry, passion, and boundless creativity. Join us as we explore
            provocative themes, ignite imaginations, and celebrate the beauty of
            visual storytelling.
          </span>
        </div>

        <div className="my-5">
          <h4 style={{ color: "white" }}>Most Viewed</h4>
          <span className="text-secondary">{totalComics} results</span>
        </div>
        <div className="row">
          {allComics.map((item) => {
            return (
              <a
                href={`/overview/${item.title}`}
                className="card border-0 col-6 col-md-2 mb-3"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "black",
                }}
              >
                <img
                  src={item.cover}
                  alt="Comic Cover"
                  style={{ borderRadius: 7 }}
                />
                <h6 className="card-title d-flex justify-content-center my-2">
                  {item.title}
                </h6>
              </a>
            );
          })}
        </div>

        {totalComics > 0 && (
          <div className="d-flex justify-content-center">
            <button className="btn btn-danger m-2" disabled>
              Prev
            </button>
            {pageUpBtn.map((item) => {
              return item;
            })}
            <a href="/page/2">
              <button className="btn btn-danger m-2">Next</button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
