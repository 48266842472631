import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/screens/Home"
import Page from "./components/screens/Page"
import Overview from "./components/screens/Overview"
import Episode from "./components/screens/Episode"
import "./App.css"


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/page/:num",
    element: <Page/>,
  },
  {
    path: "/overview/:comic",
    element: <Overview/>,
  },
  {
    path: "/episode/:comic/:episode",
    element: <Episode/>,
  },
]);

export default function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
